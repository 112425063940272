import { Typography } from "@material-ui/core";
import * as React from "react";
import Layout from "../layouts/Layout";

const Visit = () => {
  return (
    <Layout title="Visit">
      <Typography
        variant="h1"
        style={{ margin: "72px 0", textAlign: "center" }}
      >
        Visit
      </Typography>
    </Layout>
  );
};

export default Visit;
